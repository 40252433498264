/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    min-height: 100vh;
    font-family: 'Mulish', sans-serif;
    font-size: 1rem;
}

.img-logo-modals-goals{
    width: 194px;
}

.range-input{
    accent-color: blue;
}

.total-different{
    background-color: #f5c2c7;
}

#range-hours{
    transform: rotate(270deg);
    width: 200px;
    margin-top: 80px;
    margin-bottom: 50px;
}

.footer{
    margin-top: 100px;
    height: 250px;
    background-color: #2b2e2e;
    background-color: #2b2e2e;
    margin-top: 100px;
}